@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Syne&display=swap');

body {
  margin: 0;
  font-family: 'Syne', sans-serif;
  -webkit-font-smoothing: antialiased;
}

code {
  font-family: 'Syne', sans-serif;
}
